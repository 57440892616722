import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import {Overlay, FullscreenVideo, Layout} from "../components"
import {RecoilRoot} from "recoil"

const Home = (): JSX.Element => {
  const {
    datoCmsHomepage: {video},
  } = useStaticQuery(graphql`
    query HomepageQuery {
      datoCmsHomepage {
        video {
          landscape
          portrait
          subtitles {
            url
          }
        }
      }
    }
  `)

  return (
    <RecoilRoot>
      <Layout>
        <FullscreenVideo video={video} />
        <Overlay />
      </Layout>
    </RecoilRoot>
  )
}

export default Home
